import React, { useEffect, useState } from 'react'
import {
  Box,
  chakra,
  Flex,
  FlexProps,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'

import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import CustomImage from '../Image'
import CustomButton from '../Button'
import AccordionComponent from './faq/AccordionComponent'
import { sidePanelThemeColor } from '@/lib/constants/colorConstants'
import HelpModal from '../Help/HelpModal'
import { leftVarients, rightVarients } from '@/lib/animations'

const DATAFORMAT = [
  {
    id: 1,
    title: 'How do I know the perfect plan for me?',
    description: `Here's the thing... If you want a candidate to carry out a one-time test, get your results instantly and be done, then PAY AS YOU GO is the perfect fit for you. `,
    secondDescription:
      'If you want a candidate to take more than 1 test within 30 days, then STANDARD PLAN fits you perfectly. Example,  schools',
  },

  {
    id: 2,
    title: 'How are the units calculated?',
    description: `It's like your electricity and phone bills. You only pay for what you use. Units are calculated per candidate taking the tests or exams.`,
    secondDescription: '1 unit = 40 naira ',
  },
  {
    id: 3,
    title: 'Is it only for tests? ',
    description: `No, it isn't. TestNotion is the perfect tool for your exams, quizzes, assessments, and aptitude tests. As far as it's online, TestNotion is your best fit.`,
  },
  {
    id: 4,
    title: 'What is live monitoring? ',
    description:
      'Think of it as an online invigilation. It allows you to watch, oversee, and check the conduct of candidates, anywhere, anytime.',
  },
  // {
  //   id: 5,
  //   title: 'Can I administer tests without the internet?',
  //   description:
  //     'Yes, you can. Our LAN version is the perfect solution for schools, mass recruiters, and admins who want to self-host their tests, and exams offline. And have 100% control.',
  //   link: 'Learn more about it here.',
  // },
  {
    id: 6,
    title: 'Is it virus, glitch, or hack-proof? ',
    description:
      'Wondering how safe and secure our server is?  We use end-to-end user encryption to keep data between the admins and candidates. Built-in layers of privacy. To keep tests safe and personal.',
    secondDescription:
      'Like secret messages between two users. Only the receiver can see, and understand what the sender sends.',
  },
  {
    id: 7,
    title: 'Can I use it for a larger number of candidates? ',
    description:
      'Yes! It can. TestNotion supports an unlimited capacity of candidates taking tests all at once.',
  },
  {
    id: 8,
    title: 'How do I notify candidates before tests?',
    description:
      'Emails and SMS notifications are sent to candidates automatically once tests are ready.',
  },
  {
    id: 9,
    title: 'Can I download the recorded videos for future use?',
    description:
      'Yes, you can. But downloading videos for storage requires extra units.',
  },
  {
    id: 10,
    title: 'If I get stuck (internet, network issues) what happens to my test?',
    description: `As far as you haven't completed or submitted your tests. The system automatically takes you back to where you stopped when you log in again.`,
  },
]

export default function FAQ(props: FlexProps) {
  const { colorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const controls = useAnimation()
  const { inView, ref } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const [help, setHelp] = useState<any>(false)

  const handleHelpModal = () => {
    setHelp(true)
    onOpen()
  }

  return (
    <chakra.section
      w="full"
      pb="6.25rem"
      pt="6.25rem"
      {...props}
      background={sidePanelThemeColor[colorMode]}
      id="faq"
      overflowX={'hidden'}
      mx="auto"
    >
      <Box
        maxW="full"
        as={motion.div}
        animate={controls}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        p="0.8rem"
        mx="auto"
      >
        <Flex
          padding={['0 1rem', '0 6rem']}
          maxW="container.xl"
          columnGap={[2, 4, 6, 14]}
          rowGap={10}
          justifyContent="space-between"
          flexDir={['column', 'row']}
          alignItems={['', 'center']}
          mx="auto"
        >
          <CustomImage
            width={418}
            height={378}
            fill
            src="/faq__1_-removebg-preview 1.svg"
            flex={1}
            as={motion.div}
            variants={leftVarients}
          />
          <Box flex={2} as={motion.div} variants={rightVarients}>
            {DATAFORMAT &&
              DATAFORMAT.length > 0 &&
              DATAFORMAT.map(data => (
                <AccordionComponent
                  key={data.id}
                  title={data.title}
                  description={data.description}
                  seconDescription={data.secondDescription}
                  // link={data.link}
                />
              ))}
          </Box>
        </Flex>
        <Text
          textAlign="center"
          fontSize={['1rem', '1.5rem']}
          fontWeight="medium"
          mb="3rem"
          mt="4rem"
        >
          Did not get what you are looking for?
        </Text>
        <Box textAlign="center">
          <CustomButton
            maxW="12rem"
            maxH="3rem"
            fontWeight="medium"
            borderRadius={'5px'}
            onClick={handleHelpModal}
            fontSize={{ base: '0.8rem', md: '1rem' }}
          >
            Get Help
          </CustomButton>
          {help && (
            <HelpModal isOpen={isOpen} onClose={onClose} onClick={onClose} />
          )}
        </Box>
      </Box>
    </chakra.section>
  )
}
