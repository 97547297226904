import { BsDisplay, BsReceipt } from 'react-icons/bs'
import { IoIosPeople } from 'react-icons/io'
import { IoShieldCheckmarkOutline } from 'react-icons/io5'
import { MdOutlineNotificationAdd } from 'react-icons/md'
import { TbNotes } from 'react-icons/tb'

export const SECTION_TITLE: string = `Making Test `
export const SECTION_TITLE_LAN: string = 'Benefits'

export const benefitsWithStart = [
  {
    id: 1,
    title: 'Auto record results',
    icon: BsReceipt,
  },
  {
    id: 2,
    title: 'Carry out Tests in mass ',
    icon: IoIosPeople,
  },
  {
    id: 3,
    title: 'Assign roles to users',
    icon: IoShieldCheckmarkOutline,
  },
  {
    id: 4,
    title: 'Schedule and import multiple question type',
    icon: TbNotes,
  },
  {
    id: 5,
    title: 'Monitor tests Live ',
    icon: BsDisplay,
  },
  {
    id: 6,
    title: 'Send invites & notifications',
    icon: MdOutlineNotificationAdd,
  },
]

export const benefits = [
  {
    title: 'All in one Tool',
    src: '/illustrator-1.svg',
    side: 'right',
    sideAnimation: 'left',
    text: [
      'Carry out and supervise tests in secconds',
      'Easy to use and understand',
      'No boring Jargon',
      'Pay for only what you use.',
    ],
    mb: '5rem',
  },
  {
    title: 'Question Bank',
    src: '/examnotification.svg',
    side: 'left',
    sideAnimation: 'right',
    text: [
      'Create multiple question types (multiple choice, fill in the blanks, true/ false) directly.',
      'Import questions from previous tests conducted.      ',
      'Bulk import questions directly.',
    ],
    mt: '5rem',
  },

  {
    title: 'Live Monitoring',
    src: '/livep.svg',
    side: 'left',
    sideAnimation: 'left',
    text: [
      'Monitor and oversee candidates remotely anywhere, anytime.',
      'Download and play live videos for future reference.',
      `Monitor and record candidates' conduct during tests.`,
      'Capture suspicious activity immediately.',
    ],
    mb: '5rem',
  },

  {
    title: 'Auto-grading and recording',
    src: '/illustrator-2.svg',
    side: 'right',
    sideAnimation: 'right',
    text: [
      'Spend fewer hours marking and recording.',
      'Automatically grade and rank test results.',
      'Track the individual performance of each candidate directly.',
    ],
    mt: '5rem',
  },
  {
    title: 'Be your own artist',
    src: '/illustrator-3.svg',
    side: 'right',
    sideAnimation: 'left',
    text: [
      'Customize logos, themes, and colors to your taste.',
      'Create customized domain names to serve as an extension of your brand and personality',
      'Add background pictures and content to personalize your look',
    ],
    mb: '5rem',
  },
  {
    title: 'Take 100% control',
    src: '/illustrator-4.svg',
    side: 'left',
    sideAnimation: 'right',
    text: [
      'Assign roles to users to carry out specific tasks and functions.',
      `Give instant feedback on candidates' performance, previous scores, the total number of candidates, and full-time taken to complete tests, and assessments.`,
      'Build your tests, quizzes, assessments, and exams exactly the way you want them.',
    ],
    mt: '5rem',
  },
]

export const lanBenefits = [
  {
    title: 'Support Mass Examination',
    src: '/lanimg1.svg',
    side: 'left',
    text: [
      'The system can handle a large number of candidates and high concurrency during the exam. Users can create random question tests and use the anti-cheating feature.',
    ],
  },
  {
    title: 'Customize Your Own Brand',
    src: '/lanimg2.svg',
    side: 'right',
    text: [
      'Upload your Logo and addon domain, make a personalized exam background, build you exclusive exam system as well as having a good knowledge of all the exam data.',
    ],
  },
  {
    title: 'Stable, Safe and Efficient',
    src: '/lanimg3.svg',
    side: 'left',
    text: [
      'APACHE + MYSQL + GO, the system is simple to extend with high security and B/S mode, and can be used not only on the Online network, but also on the LAN.',
      'It will deployed automatically during installation process. Go to try your own exam system after installation!',
      'Can be used via LAN and Internet; support more than 1,000 Student taking exam simultaneously.',
    ],
  },
  {
    title: 'Comprehensive Statistical Analysis',
    src: '/lanimg4.svg',
    side: 'right',
    text: [
      'You can group all the candidates with different score ranking. What is more, it is easy to make a comparative analysis about scores of the Student in many departments.',
    ],
  },
  {
    title:
      'Build a huge question bank to meet the needs of any types of examination',
    src: '/lanimg5.svg',
    side: 'left',
    text: [
      'Build a huge question bank to meet the needs of any types of examination.',
      'Auto identify the repeated test questions including words, pictures, audio and video.',
    ],
  },
]
