import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'
import {
  VStack,
  HStack,
  Flex,
  useColorModeValue,
  useColorMode,
  FormControl,
  useToast,
} from '@chakra-ui/react'
import { NEWS_HEAD, NEWS_DETAILS } from '@/lib/constants/newsConstants'
import { NewsDetails } from './newsletter/newsDetails'
import CustomImage from '../Image'
import CustomButton from '../Button'
import { basicTextColor, bgThemeColor } from '@/lib/constants/colorConstants'
import useForm from '@/hooks/useForm'
import { TextInput } from '../Input'
import { axiosInstance, handleErrorResponse } from '@/lib/helpers'
import { FORMDATA } from '@/lib/constants/newsConstants'
import { moveUp } from '@/lib/animations'

export const NewsLetter = () => {
  const toast = useToast()
  const { toggleColorMode: toggleMode, colorMode } = useColorMode()
  const bgColor = useColorModeValue('brand.primary.400', 'lightBlack')
  const controls = useAnimation()
  const { inView, ref } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const { handleChange, inputTypes, handleSubmit, errors, loading } = useForm({
    inputs: FORMDATA,
    cb: async inputs => {
      const data = {
        email: inputs.email,
      }

      try {
        const res = await axiosInstance.post('/config/newsletter', data)

        toast({
          title: `${res.data.message}`,
          description: '',
          status: 'success',
          variant: 'top-accent',
          duration: 4000,
          isClosable: true,
        })

        inputTypes.email = ''
      } catch (err) {
        handleErrorResponse(err)
      }
    },
  })

  return (
    <VStack
      w="full"
      mb="10.25rem"
      color="brand.primary.50"
      alignItems="center"
      justifyContent="center"
      as={motion.div}
      animate={controls}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={moveUp}
      p={'2rem'}
    >
      <HStack
        minH="22.78rem"
        maxW={'64.19rem'}
        bgColor={bgColor}
        borderRadius={['10px', '20px', '35px', '41px']}
        alignItems="center"
        justifyContent="center"
        position="relative"
        p={{ base: '1rem', md: '2rem' }}
      >
        <CustomImage
          src="/paper.svg"
          width="50px"
          height="50px"
          position="absolute"
          top="4.072rem"
          left={0}
          display={{ base: 'none', md: 'block' }}
        />
        <VStack alignItems="center">
          <NewsDetails headNews={NEWS_HEAD} details={NEWS_DETAILS} />
          <Flex
            w={{ base: 'full', md: '80%' }}
            gap={10}
            flexDir={['column', 'row']}
            alignItems="center"
          >
            <Flex flex={6} w="90%">
              <form
                action="post"
                style={{
                  width: '100%',
                }}
              >
                {FORMDATA.map((data, i) => (
                  <FormControl
                    isInvalid={errors[data.name]}
                    w="full"
                    key={`subscribe${i}`}
                  >
                    <TextInput
                      type="text"
                      name={data.name}
                      placeholder={data.label}
                      value={inputTypes[data.name]}
                      handleChange={handleChange}
                      error={errors[data.name] && data.errorMessage}
                      TextInputProps={{
                        w: 'full',
                        border: 'none',
                        borderRadius: 'none',
                        textAlign: { base: 'center', md: 'left' },
                        borderBottom: '2px solid white',
                        _placeholder: {
                          color: 'white',
                        },
                      }}
                    />
                  </FormControl>
                ))}
              </form>
            </Flex>
            <Flex flex={2}>
              <CustomButton
                color={basicTextColor[colorMode]}
                bgColor={bgThemeColor}
                w="12rem"
                h="3rem"
                fontSize={'0.9rem'}
                fontWeight="medium"
                borderRadius={'5px'}
                onClick={handleSubmit}
                isLoading={loading}
              >
                Subscribe
              </CustomButton>
            </Flex>
          </Flex>
        </VStack>
      </HStack>
    </VStack>
  )
}
