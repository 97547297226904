import React, { useEffect } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import CustomImage from '../Image'
import { feature } from '@/lib/constants/featureSupport'
import { moveUp } from '@/lib/animations'

export default function FeatureSupport() {
  const controls = useAnimation()
  const { inView, ref } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <Flex
      p="1rem 0 1rem 0"
      px={['1rem', '2rem', '4rem', '6rem']}
      w="full"
      id="features"
      mx="auto"
      as={motion.div}
      animate={controls}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={moveUp}
    >
      <Flex
        w="container.xl"
        mb="5.25rem"
        mx="auto"
        alignItems="center"
        justifyContent="space-between"
        flexDir={['column', 'row']}
        rowGap={16}
        fontSize="1.25rem"
        textAlign="center"
        columnGap={'1rem'}
      >
        {feature &&
          feature.length > 1 &&
          feature.map(el => (
            <Box
              key={`feature_support_${el.id}`}
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              <CustomImage src={el.img} width={110} height={110} fill />
              <Text>{el.title}</Text>
            </Box>
          ))}
      </Flex>
    </Flex>
  )
}
