export const footerIcons = [
  {
    src: '/assets/icons/facebook.svg',
    path: '#facebook.com',
    id: 1,
  },
  {
    src: '/assets/icons/twitter.svg',
    path: 'https://twitter.com/logicglide',
    id: 2,
  },
  {
    src: '/assets/icons/instagram.svg',
    path: '#ionic.com.ng',
    id: 3,
  },
  {
    src: '/assets/icons/linkedin.svg',
    path: '#linkdin',
    id: 4,
  },
]
