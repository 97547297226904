import { VStack, Text } from '@chakra-ui/react'

export const NewsDetails = (props: { headNews: string; details: string }) => {
  const { headNews, details } = props
  return (
    <VStack mb={['1.2rem', '4.429375rem']} textAlign="center">
      <Text fontSize={['1.5rem', '2rem']} mb="0.7875rem" fontWeight="semibold">
        {headNews}
      </Text>
      <Text
        lineHeight={1.5}
        w={{ base: '90%', md: '75%' }}
        fontSize={['0.9rem', '1.14rem']}
      >
        {details}
      </Text>
    </VStack>
  )
}
