export const navMenus = [
  {
    id: 'home',
    name: 'Home',
    path: '#home',
    type: 'regular',
  },
  {
    id: 'features',
    name: 'Features',
    path: '#features',
    type: 'regular',
  },
  {
    id: 'pricing',
    name: 'Pricing',
    path: '#pricing',
    type: 'regular',
  },
  // {
  //   id: 'lanversion',
  //   name: 'LAN Version',
  //   path: '/lanversion',
  //   type: 'notRegular',
  // },

  {
    id: 'partner',
    name: 'Partner',
    path: '/partner',
    type: 'notRegular',
  },
  {
    id: 'support',
    type: 'select',
    name: 'Support',
    path: '#support',
  },
]

export const navMenusTerms = [
  {
    id: 'home',
    name: '',
    path: '#home',
    type: 'regular',
  },
]

export const footerNav = [
  {
    id: 'home',
    name: 'Home',
    path: '#home',
    type: 'regular',
  },
  {
    id: 'features',
    name: 'Features',
    path: '#features',
    type: 'regular',
  },
  {
    id: 'benefits',
    name: 'Benefits',
    path: '#benefits',
    type: 'regular',
  },
  {
    id: 'testimonials',
    name: 'Testimonials',
    path: '#testimonials',
    type: 'regular',
  },
  {
    id: 'pricing',
    name: 'Pricing',
    path: '#pricing',
    type: 'regular',
  },
]

export const footerNavPartner = [
  {
    id: 'home',
    name: 'Home',
    path: '#home',
    type: 'regular',
  },
]

export const navMenusLan = [
  {
    id: 'home',
    name: 'Home',
    path: '#home',
    type: 'regular',
  },
  {
    id: 'features',
    name: 'Features',
    path: '#features',
    type: 'regular',
  },
  {
    id: 'benefits',
    name: 'Benefits',
    path: '#benefits',
    type: 'regular',
  },
  {
    id: 'testimonials',
    name: 'Testimonials',
    path: '#testimonials',
    type: 'regular',
  },
  {
    id: 'support',
    type: 'select',
    name: 'Support',
    path: '#support',
  },
]
