import { EMAIL_REGEX } from './regexConstants'

export const NEWS_HEAD = 'Subscribe Our Newsletter'
export const NEWS_DETAILS =
  'Join our newsletter and be the first to know about new product release, discounts and useful resources!'

export const FORMDATA = [
  {
    id: 1,
    label: 'Enter your email',
    name: 'email',
    type: 'text',
    placeholder: 'Enter your email',
    required: true,
    pattern: EMAIL_REGEX,
    errorMessage: 'Please Enter a valid email',
    span: 1,
  },
]
