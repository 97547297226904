import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react'
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'
import { CustomLink } from '@/components/Link'

type IAccordion = {
  title: string
  description: string
  seconDescription?: string
  link?: string
}

export default function AccordionComponent({
  title,
  description,
  seconDescription,
  link,
}: IAccordion) {
  return (
    <>
      <Accordion allowToggle={true}>
        <AccordionItem border="none" maxW="40rem">
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton
                  _focus={{}}
                  display="flex"
                  justifyContent="space-between"
                  w="full"
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    fontSize={{ base: '1rem', md: '1.2rem' }}
                    fontWeight="medium"
                  >
                    {title}
                  </Box>
                  {isExpanded ? (
                    <FaAngleDown fontSize="12px" />
                  ) : (
                    <FaAngleRight fontSize="12px" />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} fontSize={{ base: '0.8rem', md: '1rem' }}>
                <Text>{description}</Text>
                {seconDescription && (
                  <Text mt="0.5rem">{seconDescription}</Text>
                )}
                {link && (
                  <CustomLink as="span" href={`/`}>
                    {link}
                  </CustomLink>
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </>
  )
}
