export const heroHeading = {
  heading: 'A New way to carry out, Check & Auto-record tests with one tool',
  paragraphFirst: 'Kiss paper, red pens, and Answer sheets Goobye.',
  paragraphSecond: 'Test, manage and qualify candidates conveniently and fast',
}

export const heroHeadingPartner = {
  heading: 'Get Paid When You Refer Admins to TestNotion',
  paragraphFirst: 'Become a TestNotion partner.',
  paragraphSecond:
    'Refer, and earn commissions for every admin who uses TestNotion through you.',
}

export const lanheroHeading = {
  heading1: 'Stable, Safe & Efficient ',
  heading2: 'LAN Exam System ',
  paragraph:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit ametluctus venenatis',
}
