export const feature = [
  {
    id: 1,
    img: '/server.svg',
    title: 'Secure Server',
  },
  {
    id: 2,
    img: '/connection.svg',
    title: 'Reliable Connection',
  },
  {
    id: 3,
    img: '/operation.svg',
    title: 'Smooth Operation',
  },
  {
    id: 4,
    img: '/fastoperation.svg',
    title: 'Fast Operations',
  },
]
